import React, { Component } from 'react'
import SearchResultRowPlaces from './SearchResultRowPlaces';

export default class SearchResultsPlaces extends Component {

    render() {
        const { items, favoritesPlaces, showFavoritesOnly } = this.props;
        
        // Filter items if showing favorites only
        const filteredItems = showFavoritesOnly 
            ? items.filter(item => favoritesPlaces.includes(item.id))
            : items;

        //console.log(this.props.items);

        return (

            <div>

                {filteredItems ? <div className="SearchResults">

                    {filteredItems.map(item => { if (true) {
                  
                        return  <SearchResultRowPlaces favoritesPlaces={favoritesPlaces} chageFavorites={(chageFavorites) => this.props.chageFavorites(chageFavorites) } item={item} showOpen={this.props.showOpen} key={item.id} />
                    } else return false}) }
                    
                </div> : <h2 className="noResults" >שגיאה</h2> }

            </div>
        )

    }
}
