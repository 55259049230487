import React, { Component } from 'react'
import SearchResultRow from './SearchResultRow';

export default class SearchResults extends Component {
    render() {
        const { sayarotData, activeSayeretId, changeMenuright, StartDateRegistrationSayerot } = this.props;

        if (!Array.isArray(sayarotData)) {
            return <h2 className="noResults">אין נתונים זמינים</h2>;
        }

        console.log('SearchResults - StartDateRegistrationSayerot:', StartDateRegistrationSayerot);

        return (
            <div>
                <div className="SearchResults">
                    {sayarotData.map(item => (
                        <SearchResultRow
                            key={item.ID || item.id}
                            activeSayeretId={activeSayeretId}
                            changeMenuright={(id) => changeMenuright(id)}
                            item={item}
                            picChangeName={0}
                            isMyPage={false}
                            StartDateRegistrationSayerot={StartDateRegistrationSayerot}
                        />
                    ))}
                </div>
            </div>
        );
    }
}
