import React, { useEffect } from "react";
import HomePage from "../../Pages/HomePage";
import RegisterIndex from "../../Pages/Register/RegisterIndex";
import UserConsoleSearchIndex from "../../Pages/UserConsole/UserConsoleSearchIndex";
import UserConsoleDataIndex from "../../Pages/UserConsole/UserConsoleDataIndex";
import SherutPlacesIndex from "../../Pages/MekomotSherut/SherutPlacesIndex";
import FilesPage from "../../Pages/UserConsole/files/FilesPage";
import ClockInOutIndex from "../../Pages/clockInOut/ClockInOutIndex";
import TrainingIndex from "../../Pages/training/TrainingIndex";
import SortiesIndex from "../../Pages/sorties/SortiesIndex";
import ContactUsIndex from "../../Pages/contactUs/ContactUsIndex";
import AvatarEditPage from "../../Pages/UserConsole/closeApp/AvatarEditPage";

const ContentWrapper = ({ page, info, changeMenuright }) => {
  // check if userData is undefined and set it to {} if it is
  const userData = localStorage.getItem("userData") !== undefined ? JSON.parse(localStorage.getItem("userData") || "{}") : {};
  // if userData is undefined, logout
  if (userData === undefined) {
    localStorage.removeItem("userData");
    window.location.href = "/";
  }

  useEffect(() => {
    // Enhanced WebView detection
    const isWebView = () => {
      const userAgent = navigator.userAgent.toLowerCase();
      const hasReactNativeWebView = typeof window.ReactNativeWebView === 'object' && window.ReactNativeWebView !== null;
      const isWebkit = userAgent.includes('webkit');
      const isWKWebView = userAgent.includes('wkwebview');
      const isAndroidWebView = userAgent.includes('wv');
      
      // Log detection details for debugging
      console.log('WebView Detection:', {
        hasReactNativeWebView,
        userAgent,
        isWebkit,
        isWKWebView,
        isAndroidWebView,
        isWebView: hasReactNativeWebView || isWKWebView || isAndroidWebView
      });

      return hasReactNativeWebView || isWKWebView || isAndroidWebView;
    };

    const isReactNative = isWebView();
    const userData = JSON.parse(localStorage.getItem("userData") || "{}");
    const isLoggedIn = userData && Object.keys(userData).length > 0 && !["login", "register", "reSendPass"].includes(page);

    // Handle camera permissions only in React Native environment
    let handleCameraPermissions;
    if (isReactNative) {
      handleCameraPermissions = () => {
        navigator.mediaDevices.getUserMedia({ video: true })
          .then(stream => {
            // Camera access granted
            stream.getTracks().forEach(track => track.stop()); // Clean up the stream
          })
          .catch(error => {
            console.error("Camera access error:", error);
          });
      };

      window.addEventListener("message", handleCameraPermissions);
    }

    // Send app state message with enhanced device info
    const message = JSON.stringify({
      isLoggedIn: isLoggedIn.toString(),
      Category: userData.Category || "2",
      InService: userData.InService || "null",
      deviceInfo: {
        userAgent: navigator.userAgent,
        platform: navigator.platform,
        isWebView: isReactNative,
        webViewType: isReactNative ? 'ReactNativeWebView' : 'browser'
      }
    });

    // Enhanced postMessage handling with error catching
    try {
      if (window.ReactNativeWebView && typeof window.ReactNativeWebView.postMessage === 'function') {
        window.ReactNativeWebView.postMessage(message);
        console.log('Message sent to ReactNativeWebView:', message);
      } else {
        window.postMessage(message, '*');
        console.log('Message sent to window:', message);
      }
    } catch (error) {
      console.error('Error sending postMessage:', error);
    }

    // Cleanup
    return () => {
      if (isReactNative && handleCameraPermissions) {
        window.removeEventListener("message", handleCameraPermissions);
      }
    };
  }, [page]);

  const renderContent = () => {
    const isDevUser = true; // This should be determined dynamically based on your app's logic

    switch (page) {
      case "index":
        return <HomePage siteInfo={{ info }} />;
      case "register":
      case "login":
      case "loginQueryUrl":
      case "reSendPass":
        return <RegisterIndex siteInfo={{ info }} page={page} />;
      case "userIndex":
        return <UserConsoleSearchIndex siteInfo={{ info }} changeMenuright={changeMenuright} />;
      case "userSearch":
        return <UserConsoleSearchIndex siteInfo={{ info }} />;
      case "userData":
        return <UserConsoleDataIndex siteInfo={{ info }} />;
      case "files":
        return <FilesPage siteInfo={{ info }} />;
      case "clockInOutIndex":
        return isDevUser ? <ClockInOutIndex siteInfo={{ info }} /> : <h2 style={{ color: "red" }}>שגיאה</h2>;
      case "training":
        return isDevUser ? <TrainingIndex siteInfo={{ info }} /> : <h2 style={{ color: "red" }}>שגיאה</h2>;
      case "sorties":
        return <SortiesIndex siteInfo={{ info }} />;
      case "contactUs":
        return <ContactUsIndex siteInfo={{ info }} />;
      case "editAvatar":
        return <AvatarEditPage siteInfo={{ info }} />;
      case "sherutPlacesIndex":
        return <SherutPlacesIndex siteInfo={{ info }} />;
      default:
        return <div>NO PAGE</div>;
    }
  };

  return <>{renderContent()}</>;
};

export default ContentWrapper;
