/* eslint-disable default-case */
import { RestUrls } from "../../Components/-Helpers-/config";
import { toast } from 'react-toastify';
import axios from 'axios';
import validator from "validator";
import moment from 'moment-timezone';
import 'moment/locale/he';

// Get user's timezone offset in minutes
const getUserTimezoneOffset = () => {
  try {
    const israelTZ = 'Asia/Jerusalem';
    const date = new Date();
    
    // Simple calculation for Israel timezone
    const now = new Date();
    const jan = new Date(now.getFullYear(), 0, 1);
    const jul = new Date(now.getFullYear(), 6, 1);
    const isDST = now.getTimezoneOffset() < Math.max(jan.getTimezoneOffset(), jul.getTimezoneOffset());
    
    return isDST ? 180 : 120; // 180 minutes for DST, 120 for standard time
  } catch (error) {
    console.error('Timezone detection error:', error);
    // Default to Israel timezone offset
    return 120; // Default to +2 hours
  }
};

// Process time string to show in original timezone (+2)
const processTimeWithOriginalZone = (timeStr) => {
  if (!timeStr) return '';
  try {
    // Convert UTC time to Israel time
    const date = new Date(timeStr);
    return date.toLocaleTimeString('he-IL', {
      hour: '2-digit',
      minute: '2-digit',
      timeZone: 'Asia/Jerusalem'
    });
  } catch (error) {
    console.error('Time processing error:', error);
    return '';
  }
};

// Process Times array to show in original timezone
const processTimesWithOriginalZone = (times) => {
  return times.map(time => ({
    ...time,
    TimeF: time.TimeF ? processTimeWithOriginalZone(time.TimeF) : '',
    TimeT: time.TimeT ? processTimeWithOriginalZone(time.TimeT) : ''
  }));
};

// Create a common axios config for all API calls
const axiosConfig = {
  headers: { 
    'Content-Type': 'application/json',
    'Accept': 'application/json',
    'Cache-Control': 'no-cache',
    'Pragma': 'no-cache',
    // Add User-Agent handling
    'User-Agent': navigator.userAgent
  },
  timeout: 30000, // Increased to 30 seconds for WebView
  retry: 3,
  retryDelay: (retryCount) => {
    return retryCount * 2000; // Increased delay between retries
  },
  // Remove withCredentials from default config
  validateStatus: function (status) {
    return status >= 200 && status < 300;
  }
};

// Create separate configs for different API types
const createApiConfig = (isStatus = false) => ({
  ...axiosConfig,
  // Add timeout for mobile devices
  timeout: isStatus ? 30000 : 45000, // 30 seconds for status, 45 for others
  validateStatus: status => status < 500, // Allow non-500 responses to be handled
  // Add retry mechanism
  retry: isStatus ? 3 : 2,
  retryDelay: (retryCount) => retryCount * (isStatus ? 2000 : 3000),
  // Don't include credentials for status checks
});

export const getClockApiMaakav = async (url, sendObj, setLoading, setResponseData) => {
  setLoading(true);
  const requestWithTimezone = {
    ...sendObj,
    TimezoneOffset: getUserTimezoneOffset()
  };

  try {
    const { data } = await axios.post(`${API_BASE_URL}${url}`, requestWithTimezone, axiosConfig);

    // Process the response data preserving original timezone
    if (data?.Shib) {
      data.Shib = data.Shib.map(report => ({
        ...report,
        Dates: report.Dates.map(date => ({
          ...date,
          Times: processTimesWithOriginalZone(date.Times)
        }))
      }));
    }
    
    setResponseData(data);
    return data;
  } catch (error) {
    handleApiError(error, setResponseData, {
      url: `${API_BASE_URL}${url}`,
      method: 'POST',
      payload: requestWithTimezone
    });
  } finally {
    setLoading(false);
  }
};

const API_BASE_URL = "https://vu-apiws.azurewebsites.net/api/";

// Add new logging function
export const sendErrorLog = async (error, context = '', additionalData = {}) => {
  try {
    const errorData = {
      timestamp: new Date().toISOString(),
      error: {
        message: error?.message || 'Unknown error',
        stack: error?.stack,
        name: error?.name,
        code: error?.code,
        // Add network state information
        networkState: {
          online: navigator.onLine,
          connectionType: navigator.connection ? navigator.connection.effectiveType : 'unknown'
        }
      },
      context: context,
      request: {
        url: error?.config?.url || additionalData?.url,
        method: error?.config?.method || additionalData?.method,
        payload: additionalData?.payload, // Use the raw payload object
        headers: error?.config?.headers || additionalData?.headers
      },
      response: error?.response ? {
        status: error.response.status,
        statusText: error.response.statusText,
        data: error.response.data
      } : null,
      userInfo: {
        userData: localStorage.getItem("userData") ? JSON.parse(localStorage.getItem("userData")) : null,
        url: window.location.href,
        userAgent: navigator.userAgent,
        platform: navigator.platform,
        isMobile: /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)
      }
    };

    // Send the error log without additional stringification
    await axios.post('https://webhooks.wdev.co.il/api/aguda-error-logs', errorData, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
  } catch (logError) {
    console.error('Failed to send error log:', logError, logError?.response?.data);
  }
};

const handleApiError = async (error, setResponseData, additionalData = {}) => {
  console.error('API Error:', error);
  setResponseData({});

  // Check for specific error conditions
  if (error.response?.status === 401) {
    toast.error('נדרשת התחברות מחדש', { toastId: 'auth-error' });
    return;
  }

  if (!navigator.onLine) {
    toast.error('אין חיבור לאינטרנט', { toastId: 'network-error' });
    return;
  }

  // Enhanced error logging with more context
  await sendErrorLog(error, 'API Request Failed', {
    ...additionalData,
    platform: {
      userAgent: navigator.userAgent,
      platform: navigator.platform,
      vendor: navigator.vendor,
      language: navigator.language
    },
    networkInfo: {
      online: navigator.onLine,
      connectionType: navigator.connection ? navigator.connection.effectiveType : 'unknown',
      downlink: navigator.connection ? navigator.connection.downlink : 'unknown'
    }
  });

  // Show appropriate error message
  const errorMessage = error.response?.data?.ErrorMessage || error.message || 'שגיאה';
  toast.error(errorMessage, { toastId: 'error' });
};

export const getFromApiSherutLeumi = async (url, sendObj, setLoading, setResponseData) => {
  setLoading(true);
  try {
    const response = await axios.post(`${RestUrls.sherutLeumiApi}${url}`, sendObj, axiosConfig);
    setResponseData(response);
  } catch (error) {
    handleApiError(error, setResponseData, {
      url: `${RestUrls.sherutLeumiApi}${url}`,
      method: 'POST',
      payload: sendObj
    });
  } finally {
    setLoading(false);
  }
};

export const getClockApiMaakavStatus = async (url, sendObj, setLoading, setResponseData, setSelectedReportType, setTextAreaReport) => {
  setLoading(true);
  const requestWithTimezone = {
    ...sendObj,
    TimezoneOffset: getUserTimezoneOffset(),
    DeviceInfo: {
      platform: navigator.platform,
      userAgent: navigator.userAgent,
      isMobile: /iPhone|iPad|iPod|Android/i.test(navigator.userAgent),
      isIOS: /iPhone|iPad|iPod/i.test(navigator.userAgent),
      appVersion: navigator.appVersion,
      isWebView: /wv/.test(navigator.userAgent)
    }
  };
  
  let retryCount = 0;
  const maxRetries = 3;
  
  while (retryCount < maxRetries) {
    try {
      const statusConfig = {
        ...createApiConfig(true),
        // Increase timeout for WebView
        timeout: /wv/.test(navigator.userAgent) ? 45000 : 30000
      };
      
      console.log('MaakavStatus Request:', {
        url: `${API_BASE_URL}${url}`,
        payload: requestWithTimezone,
        deviceInfo: requestWithTimezone.DeviceInfo,
        config: statusConfig,
        retryAttempt: retryCount + 1
      });

      const response = await axios.post(`${API_BASE_URL}${url}`, requestWithTimezone, statusConfig);

      console.log('MaakavStatus Response:', {
        status: response.status,
        data: response.data,
        headers: response.headers
      });

      if (!response.data) {
        throw new Error('לא התקבל מידע מהשרת');
      }

      if (response.data.Result !== 'Success') {
        setResponseData(false);
        throw new Error('שגיאה בקריאת סטטוס');
      }

      setSelectedReportType(response.data?.IsCurrIn ? response.data?.Typ : false);
      setTextAreaReport(response.data?.IsCurrIn ? response.data?.MoreInfo : false);

      // Cache the server response for timezone consistency
      if (response.data?.TimeF || response.data?.TimeT) {
        const serverResponse = {
          Dates: [{
            Times: [{
              TimeF: response.data.TimeF || '',
              TimeT: response.data.TimeT || ''
            }]
          }]
        };
        try {
          localStorage.setItem('lastServerResponse', JSON.stringify(serverResponse));
        } catch (storageError) {
          console.error('Failed to cache server response:', storageError);
        }
      }

      if (response.data?.IsCurrIn && response.data?.TimeF) {
        try {
          // Parse the ISO timestamp from the response
          const timeStr = response.data.TimeF; // Format: "2025-01-30T09:04:00+00:00"
          console.log('Processing time:', timeStr);
          
          // Create moment object in Israel timezone and set locale to Hebrew
          const israelTZ = 'Asia/Jerusalem';
          moment.locale('he');
          const time = moment(timeStr).tz(israelTZ);
          console.log('Created moment object:', time.format());
          
          // Format time in Israel timezone
          const timeValue = time.format('HH:mm');
          console.log('Formatted time:', timeValue);

          // Get weekday and date in Hebrew
          const weekday = time.format('dddd');
          const day = time.format('DD/MM/YY');
          
          setResponseData({
            api: response.data,
            time: timeValue,
            timeAllValues: {
              hours: time.hours(),
              minutes: time.minutes(),
              utcTime: response.data.TimeF
            },
            weekday: weekday,
            day: day
          });

          console.log('Set response data:', {
            time: timeValue,
            weekday,
            day
          });
        } catch (timeError) {
          console.error('Error processing time data:', timeError);
          // Use basic time formatting as fallback
          const time = new Date(response.data.TimeF);
          const fallbackTime = time.toLocaleTimeString('he-IL', {
            hour: '2-digit',
            minute: '2-digit',
            hour12: false,
            timeZone: 'Asia/Jerusalem'
          });
          
          setResponseData({
            api: response.data,
            time: fallbackTime,
            timeAllValues: {
              hours: time.getHours(),
              minutes: time.getMinutes(),
              utcTime: response.data.TimeF
            },
            weekday: 'לא זמין',
            day: time.toLocaleDateString('he-IL', { timeZone: 'Asia/Jerusalem' })
          });
        }
      } else {
        setResponseData(false);
      }
      break; // Exit retry loop on success
      
    } catch (error) {
      retryCount++;
      
      // Special handling for timeout in WebView
      if (error.code === 'ECONNABORTED' && /wv/.test(navigator.userAgent)) {
        console.log(`WebView timeout retry ${retryCount}/${maxRetries}`);
        if (retryCount < maxRetries) {
          await new Promise(resolve => setTimeout(resolve, 2000 * retryCount));
          continue;
        }
      }
      
      const errorDetails = {
        originalError: error,
        message: error.message,
        response: error.response?.data,
        status: error.response?.status,
        headers: error.response?.headers,
        requestData: requestWithTimezone,
        networkInfo: {
          online: navigator.onLine,
          connectionType: navigator.connection ? navigator.connection.effectiveType : 'unknown',
          downlink: navigator.connection ? navigator.connection.downlink : 'unknown',
          rtt: navigator.connection ? navigator.connection.rtt : 'unknown'
        },
        retryAttempt: retryCount
      };
      
      // If this was the last retry, handle the error
      if (retryCount === maxRetries) {
        console.error('MaakavStatus Error Details:', errorDetails);
        
        let errorMessage = 'שגיאה בקריאת סטטוס';
        if (!navigator.onLine) {
          errorMessage = 'אין חיבור לאינטרנט, נא לבדוק את החיבור ולנסות שוב';
        } else if (error.code === 'ECONNABORTED') {
          errorMessage = 'השרת לא מגיב כרגע, אנא נסה שוב מאוחר יותר';
        } else if (error.response?.status === 401) {
          errorMessage = 'נדרשת התחברות מחדש';
        } else if (error.response?.status === 404) {
          errorMessage = 'השירות לא זמין כרגע, אנא נסה שוב מאוחר יותר';
        } else if (error.response?.data?.ErrorMessage) {
          errorMessage = error.response.data.ErrorMessage;
        }
        
        setResponseData(false);
        setSelectedReportType(false);
        setTextAreaReport(false);
        
        handleApiError(error, setResponseData, {
          url: `${API_BASE_URL}${url}`,
          method: 'POST',
          payload: requestWithTimezone,
          errorDetails,
          errorMessage
        });
        break;
      }
    }
  }
  
  setLoading(false);
};

export const getClockApiMaakavOut = async (url, sendObj, setLoading, setResponseData, setSelectedReportType, setTextAreaReport, setPercentWorked, setLastExit = false) => {
  setLoading(true);
  const requestWithTimezone = {
    ...sendObj,
    TimezoneOffset: getUserTimezoneOffset(),
    DeviceInfo: {
      platform: navigator.platform,
      userAgent: navigator.userAgent,
      isMobile: /iPhone|iPad|iPod|Android/i.test(navigator.userAgent),
      isIOS: /iPhone|iPad|iPod/i.test(navigator.userAgent),
      appVersion: navigator.appVersion
    }
  };
  
  try {
    const apiConfig = createApiConfig(false);
    
    console.log('MaakavOut Request:', {
      url: `${API_BASE_URL}${url}`,
      payload: requestWithTimezone,
      deviceInfo: requestWithTimezone.DeviceInfo,
      config: apiConfig
    });

    const response = await axios.post(`${API_BASE_URL}${url}`, requestWithTimezone, apiConfig);

    console.log('MaakavOut Response:', {
      status: response.status,
      data: response.data,
      headers: response.headers
    });

    if (!response.data) {
      throw new Error('לא התקבל מידע מהשרת');
    }

    if (response.data.Result === 'Error') {
      throw new Error(response.data.ErrorMessage || 'שגיאה ביציאה מהמערכת');
    }

    setResponseData(false);
    setSelectedReportType(false);
    setTextAreaReport(false);
    setPercentWorked(false);
    toast.success('היציאה התקבלה בהצלחה');

    if (setLastExit && response.data?.TimeT) {
      try {
        const time = new Date(response.data.TimeT);
        const timeValue = time.toLocaleTimeString('he-IL', {
          hour: '2-digit',
          minute: '2-digit',
          timeZone: 'Asia/Jerusalem'
        });
        setLastExit(timeValue);
      } catch (timeError) {
        console.error('Error formatting exit time:', timeError);
        const time = new Date(response.data.TimeT);
        setLastExit(time.toLocaleTimeString('he-IL', {
          hour: '2-digit',
          minute: '2-digit',
          timeZone: 'Asia/Jerusalem'
        }));
      }
    }

    return response.data;
  } catch (error) {
    const errorDetails = {
      originalError: error,
      message: error.message,
      response: error.response?.data,
      status: error.response?.status,
      headers: error.response?.headers,
      requestData: requestWithTimezone,
      networkInfo: {
        online: navigator.onLine,
        connectionType: navigator.connection ? navigator.connection.effectiveType : 'unknown',
        downlink: navigator.connection ? navigator.connection.downlink : 'unknown',
        rtt: navigator.connection ? navigator.connection.rtt : 'unknown'
      },
      deviceInfo: requestWithTimezone.DeviceInfo
    };
    console.error('MaakavOut Error Details:', errorDetails);
    
    let errorMessage = 'שגיאה ביציאה מהמערכת';
    if (!navigator.onLine) {
      errorMessage = 'אין חיבור לאינטרנט, נא לבדוק את החיבור ולנסות שוב';
    } else if (error.code === 'ECONNABORTED') {
      errorMessage = 'תם הזמן המוקצב לפעולה, נא לנסות שוב';
    } else if (error.response?.status === 401) {
      errorMessage = 'נדרשת התחברות מחדש';
    } else if (error.response?.status === 404) {
      errorMessage = 'השירות לא זמין כרגע, אנא נסה שוב מאוחר יותר';
    } else if (error.response?.data?.ErrorMessage) {
      errorMessage = error.response.data.ErrorMessage;
    }
    
    handleApiError(error, setResponseData, {
      url: `${API_BASE_URL}${url}`,
      method: 'POST',
      payload: requestWithTimezone,
      errorDetails,
      errorMessage
    });
  } finally {
    setLoading(false);
  }
};

export const getClockApiMaakavIn = async (url, sendObj, setLoading, setResponseData, setSelectedReportType, setTextAreaReport) => {
  setLoading(true);
  const requestWithTimezone = {
    ...sendObj,
    TimezoneOffset: getUserTimezoneOffset(),
    DeviceInfo: {
      platform: navigator.platform,
      userAgent: navigator.userAgent,
      isMobile: /iPhone|iPad|iPod|Android/i.test(navigator.userAgent),
      isIOS: /iPhone|iPad|iPod/i.test(navigator.userAgent),
      appVersion: navigator.appVersion
    }
  };
  
  try {
    const apiConfig = createApiConfig(false);
    
    console.log('MaakavIn Request:', {
      url: `${API_BASE_URL}${url}`,
      payload: requestWithTimezone,
      deviceInfo: requestWithTimezone.DeviceInfo,
      config: apiConfig
    });

    const response = await axios.post(`${API_BASE_URL}${url}`, requestWithTimezone, apiConfig);

    console.log('MaakavIn Response:', {
      status: response.status,
      data: response.data,
      headers: response.headers
    });

    if (!response.data) {
      throw new Error('לא התקבל מידע מהשרת');
    }

    if (response.data.Result === 'Error') {
      throw new Error(response.data.ErrorMessage || 'שגיאה בכניסה למערכת');
    }

    if (response.data.Result === 'Success') {
      toast.success('הכניסה התקבלה בהצלחה');
      
      const userJ = JSON.parse(localStorage.getItem("userData"));
      const newSendObj = { IDNumber: userJ.IDNO, SessionKey: userJ.SessionKey };
      
      // Use status config for status check
      await getClockApiMaakavStatus('v2/volunteer/MaakavStatus', newSendObj, setLoading,
        setResponseData, setSelectedReportType, setTextAreaReport);
        
      return response.data;
    }

    setResponseData(response.data);
    return response.data;
  } catch (error) {
    const errorDetails = {
      originalError: error,
      message: error.message,
      response: error.response?.data,
      status: error.response?.status,
      headers: error.response?.headers,
      requestData: requestWithTimezone,
      networkInfo: {
        online: navigator.onLine,
        connectionType: navigator.connection ? navigator.connection.effectiveType : 'unknown',
        downlink: navigator.connection ? navigator.connection.downlink : 'unknown',
        rtt: navigator.connection ? navigator.connection.rtt : 'unknown'
      },
      deviceInfo: requestWithTimezone.DeviceInfo
    };
    console.error('MaakavIn Error Details:', errorDetails);
    
    let errorMessage = 'שגיאה בכניסה למערכת';
    if (!navigator.onLine) {
      errorMessage = 'אין חיבור לאינטרנט, נא לבדוק את החיבור ולנסות שוב';
    } else if (error.code === 'ECONNABORTED') {
      errorMessage = 'תם הזמן המוקצב לפעולה, נא לנסות שוב';
    } else if (error.response?.status === 401) {
      errorMessage = 'נדרשת התחברות מחדש';
    } else if (error.response?.status === 404) {
      errorMessage = 'השירות לא זמין כרגע, אנא נסה שוב מאוחר יותר';
    } else if (error.response?.data?.ErrorMessage) {
      errorMessage = error.response.data.ErrorMessage;
    }
    
    handleApiError(error, setResponseData, {
      url: `${API_BASE_URL}${url}`,
      method: 'POST',
      payload: requestWithTimezone,
      errorDetails,
      errorMessage
    });
  } finally {
    setLoading(false);
  }
};

// Hebrew month names mapping
const hebrewMonths = {
  1: 'ינואר',
  2: 'פברואר',
  3: 'מרץ',
  4: 'אפריל',
  5: 'מאי',
  6: 'יוני',
  7: 'יולי',
  8: 'אוגוסט',
  9: 'ספטמבר',
  10: 'אוקטובר',
  11: 'נובמבר',
  12: 'דצמבר'
};

export const showCurrentMonthName = (monthNumber, currentYear, action = false) => {
  try {
    const month = hebrewMonths[parseInt(monthNumber)];
    return action === 'monthNameOnly' ? month : `${month} ${currentYear}`;
  } catch (error) {
    console.error(error);
    toast.error('שגיאה');
    return '---';
  }
};

// Get server's current date by parsing server response
const getCurrentServerDate = () => {
    // Always use local date
    const now = new Date();
    const year = now.getFullYear().toString();
    const month = (now.getMonth() + 1).toString().padStart(2, '0');
    const day = now.getDate().toString().padStart(2, '0');
    return { 
        year, 
        month, 
        day, 
        fullDate: `${year}${month}${day}` 
    };
};

export const checkShowDays = (monthData, item) => {
    const today = getCurrentServerDate().fullDate;
    // Ensure consistent format with server data
    const itemDate = `${monthData.Year}${monthData.Month.padStart(2, '0')}${item.dayData.DOM.padStart(2, '0')}`;
    
    // Convert dates to numbers for comparison
    const todayNum = parseInt(today);
    const itemDateNum = parseInt(itemDate);
    
    console.log('Date comparison:', {
        today: todayNum,
        itemDate: itemDateNum,
        shouldShow: itemDateNum <= todayNum,
        monthData: monthData,
        item: item
    });
    
    // Show all days up to and including today
    return itemDateNum <= todayNum;
};

export const changeDay = (action, currentMonth, setCurrentMonth, currentYear, setCurrentYear, monthData = {}) => {
  const { year, month } = getCurrentServerDate();
  // Format dates for comparison
  const today = `${year}${month}`;
  const currentDate = `${currentYear}${currentMonth.toString().padStart(2, '0')}`;
  const flagMore = currentDate < today;

  switch (action) {
    case 'checkActive':
      return !flagMore;
    case 'checkEditMode':
      return !monthData?.ReportConfirmationDate;
    case 'back':
      if (currentMonth > 1) {
        setCurrentMonth(currentMonth - 1);
      } else {
        setCurrentYear(currentYear - 1);
        setCurrentMonth(12);
      }
      break;
    case 'forward':
      if (flagMore) {
        if (currentMonth < 12) {
          setCurrentMonth(currentMonth + 1);
        } else {
          setCurrentYear(currentYear + 1);
          setCurrentMonth(1);
        }
      }
      break;
  }
};

export const checkhasPerutDivuach = (selectedReportType, textAreaReport) =>
  (selectedReportType === '2' || selectedReportType === '3') || (textAreaReport && textAreaReport !== 'False');

export const checkTextAreaReportContent = (selectedReportType, textAreaReport) => {
  if (checkhasPerutDivuach(selectedReportType, textAreaReport)) {
    if (textAreaReport && validator.isNumeric(textAreaReport)) {
      toast.error('יש לרשום גם מלל');
      return false;
    }
    if (/^[A-Za-z0-9]*$/.test(textAreaReport)) {
      toast.error('אסור מילוי רק תווים באנגלית');
      return false;
    }
    return true;
  }
  return true;
};